import crudHandlers from './_crud'

export default (state, action) => {
	const { type } = action
	switch (type) {
		case 'UPDATE_UNIT_EMPLOYEE': {
			var { response, payload } = action

			return {
				...state,
				is_fetching: false,
				unit_driver: response,
			}
		}
		case 'ADD_UNIT_EMPLOYEE': {
			var { response, payload } = action

			return {
				...state,
				is_fetching: false,
				unit_driver: response,
			}
		}
		case 'GET_UNIT_TYPES': {
			var { response } = action

			return {
				...state,
				is_fetching: false,
				types: response,
			}
		}

		case 'GET_UNITS_WIALON': {
			var { response } = action

			return {
				...state,
				is_fetching: false,
				wialon: response.error ? [] : response,
			}
		}

		case 'GET_UNIT_WIALON': {
			var { response } = action

			return {
				...state,
				is_fetching: false,
				wialon: response.error ? [] : response,
			}
		}

		case 'GET_DRIVER_UNITS': {
			var { response } = action

			return {
				...state,
				is_fetching: false,
				driver_units: response,
			}
		}

		case 'GET_UNIT_ODOMETER_HISTORY': {
			var { response } = action

			return {
				...state,
				last_unit_odometer_history: response.last_odometer_values,
			}
		}

		case 'GET_UNIT_HOURMETER_HISTORY': {
			var { response } = action

			return {
				...state,
				last_unit_hourmeter_history: response.last_hourmeter_values,
			}
		}

		case 'GET_ALL_UNITS':{
			return {
				...state,
				entities: action.payload
			}
		}

		default:
			state = crudHandlers(state, action, 'unit')

			return state
	}
}
